import { Role } from '@app/models/objects/role';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectRolePresetsState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('rolePresets');

export const selectAllRolePresetsItems: (
  state: object,
) => Role[] = featureAdapter.getSelectors(selectRolePresetsState).selectAll;

export const selectRolePresetsById = (id: string) =>
  createSelector(
    selectAllRolePresetsItems,
    (allRolePresets: Role[]) => {
      if (allRolePresets) {
        return allRolePresets.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectRolePresetsError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectRolePresetsState,
  getError,
);

export const selectRolePresetsIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectRolePresetsState,
  getIsLoading,
);
