import { Role } from '@app/models/objects/role';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_REQUEST = '[RolePresets] Load Request',
  LOAD_FAILURE = '[RolePresets] Load Failure',
  LOAD_SUCCESS = '[RolePresets] Load Success',

  RESET_STATE = '[RolePresets] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { proeprty_id: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: Role[] }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | ResetSuccessAction;
