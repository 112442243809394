import { Role } from '@app/models/objects/role';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<Role> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
