import { Role } from '@app/models/objects/role';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const selectRoleState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('roleDetails');

export const selectRoleDetailsState = createFeatureSelector<State>(
  'roleDetails',
);

export const selectRoleDetailsData = createSelector(
  selectRoleDetailsState,
  (state: State) => state.role,
);

export const selectRolePresetDetailsData = createSelector(
  selectRoleDetailsState,
  (state: State) => state.preset,
);

export const selectRoleDetailsLoading = createSelector(
  selectRoleDetailsState,
  (state: State) => state.isLoading,
);
