import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { RoleDetailsResponse } from '@app/models/responses/role-details-response';
import { RolesService } from '@app/services/roles-service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as featureActions from './actions';

@Injectable()
export class RoleStoreEffects {
  constructor(
    private dataService: RolesService,
    private actions$: Actions,
    private _errorHandler: ErrorHandlerService,
  ) {}

  loadPreset$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadPresetRequestAction>(
        featureActions.ActionTypes.LOAD_PRESET_REQUEST,
      ),
      switchMap((action: featureActions.LoadPresetRequestAction) =>
        this.dataService
          .loadPresetDetails(action.payload.roleId, action.payload.property_id)
          .pipe(
            map(
              ({ data }: RoleDetailsResponse) =>
                new featureActions.LoadPresetSuccessAction({
                  item: data,
                }),
            ),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.LoadPresetFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  load$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService
          .loadDetails(action.payload.propertyId, action.payload.roleId)
          .pipe(
            map(
              ({ data }: RoleDetailsResponse) =>
                new featureActions.LoadSuccessAction({
                  item: data,
                }),
            ),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.LoadFailureAction({ error }));
            }),
          ),
      ),
    ),
  );
}
