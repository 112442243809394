import { Role } from '@app/models/objects/role';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_REQUEST = '[Role] Load Details Request',
  LOAD_FAILURE = '[Role] Load Details Failure',
  LOAD_SUCCESS = '[Role] Load Details Success',

  LOAD_PRESET_REQUEST = '[Role] Load Details Presets Request',
  LOAD_PRESET_FAILURE = '[Role] Load Details Presets Failure',
  LOAD_PRESET_SUCCESS = '[Role] Load Details Presets Success',

  RESET_STATE = '[Role] Reset State',
}

export class LoadPresetRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PRESET_REQUEST;
  constructor(public payload: { roleId: number; property_id: number }) {}
}

export class LoadPresetFailureAction implements Action {
  readonly type = ActionTypes.LOAD_PRESET_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadPresetSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PRESET_SUCCESS;
  constructor(public payload: { item: Role }) {}
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyId: number; roleId: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { item: Role }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadPresetRequestAction
  | LoadPresetSuccessAction
  | LoadPresetFailureAction
  | ResetSuccessAction;
