import { Role } from '@app/models/objects/role';

export interface State {
  isLoading?: boolean;
  role: Role;
  preset: Role;
}

export const initialState: State = {
  isLoading: false,
  role: null,
  preset: null,
};
